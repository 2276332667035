@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-color);
}

html, body {
  margin: 0;
  padding: 0;
}

ol li, ul li {
 padding: 0;
 margin: 0;
 list-style: none;
}

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}